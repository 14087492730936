import React, { useState } from "react";
import PropTypes from "prop-types";
import AppContext from "./appContext";
import { getNotionDatabases } from "../../helpers/helpers";
import { useSession } from "next-auth/react";
import { set } from "mongoose";

const AppContextProvider = ({ children }) => {
  const { data: session, status } = useSession();
  const [counter, setCounter] = useState({
    apps: 0,
    widgets: 0,
  });
  const [globalData, setGlobalData] = useState({
    profile: {
      state: "init",
      data: {},
      connectedNotion: false,
      notionData: null,
      user: null,
    },
    notion: {
      notionDbList: [],
    },
  });

  async function getProfileData() {
    setGlobalData((latestState) => ({
      ...latestState,
      profile: {
        state: "fetching",
        data: {},
        connectedNotion: false,
        user: null,
      },
    }));
    const result = await fetch(`/api/profiles`);
    const data = await result.json();

    const notionRes = await fetch(`/api/notion`);
    const notionData = await notionRes.json();

    setGlobalData((latestState) => ({
      ...latestState,
      profile: {
        state: "results",
        connectedNotion: data?.connectedNotion || false,
        data: data?.data || false,
        notionData: notionData?.data,
        user: session?.user,
      },
    }));

    if (data?.connectedNotion) {
      const notionDbList = await getNotionDatabases();
      setGlobalData((latestState) => ({
        ...latestState,
        notion: { notionDbList },
      }));
    }
  }

  async function updateWidgetAndAppsCounter() {
    try {
      const embedsData = await fetch("/api/users/embeds");
      const { data = [] } = await embedsData.json();
      if (data && data.length > 0) {
        const appsList = data?.filter((app) => app.type === "app") || [];
        const widgetsList = data?.filter((app) => app.type !== "app") || [];
        setCounter({
          apps: appsList.length,
          widgets: widgetsList.length,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  React.useEffect(() => {
    if (status === "authenticated") {
      getProfileData();
      updateWidgetAndAppsCounter();
    } else if (status === "unauthenticated") {
      setCounter({
        apps: 0,
        widgets: 0,
      });
      setGlobalData((latestState) => ({
        ...latestState,
        profile: {
          state: "unauthenticated",
          data: {},
          connectedNotion: false,
        },
      }));
    }
  }, [status]);

  return (
    <AppContext.Provider
      value={{
        globalData,
        setGlobalData,
        counter,
        setCounter,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;

export const useAppContext = () => {
  const { globalData } = React.useContext(AppContext);
  return globalData;
};

export const useAppContextForCounter = () => {
  const { counter, setCounter } = React.useContext(AppContext);
  return { counter, setCounter };
};

AppContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
