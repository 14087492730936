"use client"; // NextJS 13 requires this. Remove if you are using NextJS 12 or lower
import { useContext, useEffect } from "react";
import Script from "next/script";
import AppContext from "../../../contexts/appContext/appContext";
import { useSession } from "next-auth/react";

const FeaturebaseApp = () => {
  const { globalData = {} } = useContext(AppContext);
  const { profile: globalProfileData = {} } = globalData;
  const { data: session } = useSession();

  useEffect(() => {
    const win = window;
    if (!session) return;
    if (process.env.NODE_ENV === "development") return;

    if (typeof win.Featurebase !== "function") {
      win.Featurebase = function () {
        // eslint-disable-next-line prefer-rest-params
        (win.Featurebase.q = win.Featurebase.q || []).push(arguments);
      };
    }

    const user = session.user;
    const profileImage = user.image ? { profilePicture: user.image } : {};
    win.Featurebase(
      "identify",
      {
        organization: "notionwidgets",
        email: user.email,
        name: user.name ? user.name : user.email,
        id: user.email,
        ...profileImage,
        metadata: { plan: globalProfileData.plan },
      },
      (err) => {
        if (err) {
          console.error("Error identifying user", err);
        } else {
          console.debug("User identified successfully");
        }
      }
    );
  }, [session]);

  return (
    <>
      {process.env.NODE_ENV !== "development" && (
        <Script src="https://do.featurebase.app/js/sdk.js" id="featurebase-sdk" />
      )}
      <div></div>
    </>
  );
};

export default FeaturebaseApp;
