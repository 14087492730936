import React from "react";
import Footer from "./footer";
import Nav from "../nav";
export default class Layout extends React.Component {
  /* componentDidMount() {
    if (!window.GA_INITIALIZED) {
      initGA();
      window.GA_INITIALIZED = true;
    }
    logPageView();
  } */
  render() {
    return (
      <div className="bg-white">
        <Nav />
        {this.props.children}
        <Footer />
      </div>
    );
  }
}
