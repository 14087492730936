function ProgressCircle({
  percentage = 0,
  size = 160, // 40 * 4 (default size from example)
  strokeWidth = 3,
  circleColor = "tw-text-white",
  backgroundColor = "tw-text-gray-200 dark:tw-text-neutral-700",
  className = "",
  showPercentage = true,
}) {
  // Calculate circle properties
  const radius = 16; // From viewBox dimensions
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <div className={`tw-relative ${className}`} style={{ width: size, height: size }}>
      <svg className="tw--rotate-90 tw-w-full tw-h-full" viewBox="0 0 36 36" xmlns="http://www.w3.org/2000/svg">
        {/* Background Circle */}
        <circle
          cx="18"
          cy="18"
          r={radius}
          fill="none"
          className={`tw-stroke-current ${backgroundColor}`}
          strokeWidth={strokeWidth}
        />
        {/* Progress Circle */}
        <circle
          cx="18"
          cy="18"
          r={radius}
          fill="none"
          className={`tw-stroke-current ${circleColor}`}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
        />
      </svg>

      {/* Percentage Text */}
      {showPercentage && (
        <div className="tw-absolute tw-top-1/2 tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2 tw-pb-1">
          <span className={`tw-text-[10px] tw-font-bold ${circleColor}`}>{percentage}%</span>
        </div>
      )}
    </div>
  );
}

export default ProgressCircle;
