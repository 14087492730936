import Link from "next/link";
import React, { useState, useEffect } from "react";

function ShowOrHideByLastClick(props) {
  const [isVisible, setIsVisible] = useState(false);
  const { timestamp, component, variableName } = props;

  const handleClick = () => {
    localStorage.setItem(variableName, Date.now());
    setIsVisible(false);
  };

  useEffect(() => {
    const lastClickTimestamp = localStorage.getItem(variableName);

    if (!lastClickTimestamp || parseInt(lastClickTimestamp) < timestamp) {
      setIsVisible(true);
    }
  }, [timestamp]);

  const RenderedComponent = component;
  return isVisible ? <RenderedComponent onClick={handleClick} /> : null;
}

export default ShowOrHideByLastClick;
