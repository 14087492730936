import Spinner from "react-bootstrap/Spinner";
import Image from "next/image";
import Link from "next/link";
import { HelpCircle, LogOut, Settings, Gift, Menu as Bar, X, Star, ArrowRight } from "react-feather";
import { SETTINGS_TAB } from "../helpers/constants";
import { Fragment, useEffect, useState, useContext } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import AppContext from "../contexts/appContext/appContext";
import { useRouter } from "next/router";
import ShowOrHideByLastClick from "./common/last-click-storage";
import { signOut, useSession } from "next-auth/react";
import { useAppContextForCounter } from "../contexts/appContext/app-context-provider";
import ProgressCircle from "./common/progress-circle";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import useViewsCount from "../hooks/useViews";
import { getViewsLimit } from "../helpers/pricing-helpers";

const header = [
  { index: 0, label: "Create +", href: "/" },
  { index: 1, label: "Widgets", href: "/my-widgets", count: true },
  { index: 2, label: "Apps", href: "/my-apps", count: true },
];

const loggedOutHeader = [
  { index: 0, label: "Pricing", href: "/pricing" },
  { index: 1, label: "Sign in", href: "/auth/login" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavComponent() {
  const { counter } = useAppContextForCounter();
  const { globalData } = useContext(AppContext);
  const {
    profile: {
      data: { plan },
      widgets,
    },
  } = globalData;
  const router = useRouter();

  const loginHeader = [
    { index: 0, label: "Create +", href: "/" },
    { index: 1, label: "Widgets", href: "/my-widgets", count: true },
    { index: 2, label: "Apps", href: "/my-apps", count: true },
    {
      index: 3,
      label: `${plan && plan !== "Free" ? plan : "Upgrade"}`,
      svg: "/crown 1.svg",
      href: "/pricing",
    },
    { index: 4, label: "Settings", href: "/settings" },
    { index: 5, label: "Support", href: "/" },
    { index: 6, label: "Log out", href: "/api/auth/logout" },
  ];

  const { data: session, status } = useSession();
  const [appsCount, setAppsCount] = useState(0);
  const [widgetsCount, setWidgetsCount] = useState(0);
  const lastChangeDate = new Date("2024-10-26").getTime();

  const { views, isLoading: viewsLoading } = useViewsCount(session?.user?.email);

  // useEffect(() => {
  //   async function fetchData() {
  //     try {
  //       const embedsData = await fetch("/api/users/embeds");
  //       const { data = [] } = await embedsData.json();
  //       if (data && data.length > 0) {
  //         const appsList = data?.filter((app) => app.type === "app") || [];
  //         const widgetsList = data?.filter((app) => app.type !== "app") || [];
  //         setApps(appsList.length);
  //         setWidgets(widgetsList.length);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   }
  //   if (session) {
  //     fetchData();
  //   }
  // }, [session]);

  useEffect(() => {
    setAppsCount(counter.apps);
    setWidgetsCount(counter.widgets);
  }, [counter]);

  // const handleSubscribe = () => {
  //   window.Beacon("open");
  //   window.Beacon("prefill", {
  //     name: "",
  //     email: "",
  //     subject: "Notify me when todo widget are released",
  //     text: "Hello, I want to be notified when todo widget are released",
  //   });

  //   if (session.user) {
  //     window.Beacon("identify", paramsBeacon(session.user));
  //   }
  //   window.Beacon("navigate", "/ask/message/");
  // };

  const paramsBeacon = (user) => {
    let params = { email: user.email };
    if (user.name) {
      params = {
        name: user.name,
        ...params,
      };
    }

    return params;
  };

  function WhatsNewButton(props) {
    return (
      <Link
        className="nav-link"
        href="https://help.notion-widgets.com/article/18-changelog"
        target="_blank"
        onClick={props.onClick}
      >
        <div className="tw-relative sm:tw-mr-4 md:tw-mr-0 lg:tw-mr-0 xl:tw-mr-0 tw-rounded-md tw-bg-[#e84300] hover:tw-bg-[#b53500] tw-py-1 tw-px-[6px] tw-text-white hover:tw-text-white">
          <span className="tw-sr-only">View notifications</span>
          <Gift className="tw-text-white tw-h-4 tw-w-5 tw-mb-1" aria-hidden="true" />
        </div>
      </Link>
    );
  }

  const isLoading = () => {
    return status === "loading";
  };

  const handleOpenHelp = () => {
    window.Beacon("open");
    if (session.user) {
      window.Beacon("identify", paramsBeacon(session.user));
    }
  };

  const openFeaturePortal = () => {
    window.postMessage({
      target: "FeaturebaseWidget",
      data: {
        action: "changePage",
        payload: "MainView", // MainView, RoadmapView, CreatePost, PostsView, ChangelogView
        openWidget: true,
      },
    });
  };

  const extractName = (user) => {
    const { name, nickname } = user;
    let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
    let newName;
    if (regex.test(name)) {
      newName = nickname;
    } else {
      newName = name;
    }
    return newName;
  };

  const getAvatarImage = (user, size) => {
    if (user.image) {
      return (
        <img
          className="tw-inline-block tw-rounded-full tw-ring-[2px] tw-ring-white tw-cursor-pointer"
          src={user.image}
          alt="user pic"
          style={{ cursor: "pointer" }}
          width={size}
          height={size}
        />
      );
    } else {
      return (
        <span
          className="tw-inline-block tw-overflow-hidden tw-ring-1 tw-ring-gray-500 tw-rounded-full tw-cursor-pointer"
          style={{ width: size, height: size }}
        >
          <svg className="tw-w-full tw-h-full tw-text-gray-400" fill="currentColor" viewBox="0 0 24 24">
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        </span>
      );
    }
  };

  const isPaidUser = () => {
    return plan !== "Free";
  };

  const getMaxViews = () => {
    if (plan) {
      return getViewsLimit(plan);
    }
    return getViewsLimit("Free");
  };

  const getPercentage = () => {
    const ratio = (views / getMaxViews()) * 100;
    return Math.round(ratio > 100 ? 100 : ratio);
  };

  const handleClickOnViewsComponent = () => {
    if (getPercentage() < 100) {
      router.push(`/settings?selectedTab=${SETTINGS_TAB.PLANSSUB}`);
    } else {
      router.push("/pricing");
    }
  };

  return (
    <>
      {/* {session ? (
        <div
          className="tw-py-2 tw-text-center tw-text-white tw-text-sm tw-bg-gradient-to-r tw-from-[#290b61] tw-via-50% tw-to-[#120231]"
          // style={{ borderBottom: "1px solid white" }}
        >
          <Link
            href="https://twitter.com/notionwidgets"
            target="_blank"
            rel="noopener noreferrer"
            className="tw-text-white hover:tw-text-gray-300 tw-inline-block tw-no-underline"
          >
            ✨ Follow us on X (Twitter) to get notified on updates, new releases and promotions!
            <ArrowRight className="tw-h-4 tw-w-4 tw-mx-2" />
          </Link>
        </div>
      ) : (
        <div
          className="tw-py-2 tw-text-center tw-text-white tw-text-sm tw-bg-gradient-to-r tw-from-[#290b61] tw-via-50% tw-to-[#120231]"
          // style={{ borderBottom: "1px solid white" }}
        >
          <Link href="/pricing" className="tw-text-white hover:tw-text-gray-300 tw-inline-block tw-no-underline">
            🔒 Lock in our current rates before prices increase! Subscribe by July 31st to save.
            <ArrowRight className="tw-h-4 tw-w-4 tw-mx-2" />
          </Link>
        </div>
      )} */}
      {!isPaidUser() && (
        <div
          className="tw-py-2 tw-text-center tw-text-white tw-text-sm tw-bg-gradient-to-r tw-from-[#290b61] tw-via-50% tw-to-[#120231]"
          // style={{ borderBottom: "1px solid white" }}
        >
          <Link href="/pricing" className="tw-text-white hover:tw-text-gray-300 tw-inline-block tw-no-underline">
            🔒 Get 20% off this BLACK FRIDAY DEAL, valid until Wednesday at 11:59 pm EST!
            <ArrowRight className="tw-h-4 tw-w-4 tw-mx-2" />
          </Link>
        </div>
      )}
      <Disclosure as="nav" className="tw-bg-[#ff4a00]">
        {({ open }) => (
          <>
            <div className="tw-mx-auto tw-max-w-6xl tw-px-4 sm:tw-px-6 lg:tw-px-8">
              <div className="tw-flex tw-h-[64px] tw-justify-between">
                <div className="tw-flex tw-flex-shrink-0 tw-items-center">
                  <Link className="tw-text-white tw-text-xl tw-font-semibold tw-contents hover:tw-text-white" href="/">
                    <Image src={"/whiteLogo.svg"} width={60} height={30} alt="notion widgets logo" />
                    Notion Widgets
                  </Link>
                </div>

                <div className="tw-hidden md:tw-flex tw-items-center">
                  {(session ? header : loggedOutHeader).map((heading) => {
                    const { index, label, href } = heading;
                    return (
                      <Link
                        key={index}
                        href={href}
                        className={`tw-flex tw-no-underline tw-relative tw-rounded-md ${
                          router.pathname === href ? "tw-bg-[#b53500]" : "tw-bg-[#e84300]"
                        } tw-py-2 tw-px-[11px] tw-text-white hover:tw-text-white hover:tw-bg-[#b53500] tw-ml-2`}
                      >
                        {label}
                        {heading?.count && (
                          <div className="tw-ml-2 tw-my-auto tw-text-xs tw-font-black tw-bg-white tw-text-black tw-rounded-lg tw-px-[6px] tw-py-[2px]">
                            {index === 1 ? widgetsCount : appsCount}
                          </div>
                        )}
                      </Link>
                    );
                  })}

                  {isLoading() && !session && (
                    <Spinner
                      style={{
                        width: "20px",
                        height: "20px",
                        marginTop: "9px",
                      }}
                      animation="border"
                      variant="primary"
                    />
                  )}
                </div>

                {session ? (
                  <>
                    <div className="tw-flex tw-items-center">
                      {/* <OverlayTrigger
                        placement="bottom"
                        overlay={
                          <Tooltip>
                            {getPercentage() < 100
                              ? "Views remaining"
                              : "Max views reached. All widgets are disabled. Update your plan!"}
                          </Tooltip>
                        }
                      >
                        <div
                          className="tw-mr-2 hover:tw-bg-white/10 tw-p-0.5 tw-rounded-md hover:tw-cursor-pointer"
                          onClick={handleClickOnViewsComponent}
                        >
                          {!viewsLoading && getPercentage() > 90 && (
                            <ProgressCircle size={36} percentage={getPercentage()} />
                          )}
                        </div>
                      </OverlayTrigger> */}
                      <div className="tw-flex-shrink-0 tw-hidden md:tw-flex">
                        <button
                          type="button"
                          className="tw-relative tw-inline-flex tw-items-center tw-gap-x-1.5 tw-rounded-md tw-px-3 tw-py-2 tw-text-sm tw-font-medium tw-text-white tw-shadow-sm tw-bg-gradient-to-r tw-from-black tw-via-gray-600 tw-via-50% tw-to-black"
                          style={{ border: "none" }}
                          onClick={() => router.push("/pricing")}
                        >
                          <img className="" src={"/crown 1.svg"} alt="plan icon" width={19} height={19} />
                          {plan && plan !== "Free" ? plan : "Upgrade"}
                        </button>
                      </div>
                      <div className="md:tw-ml-4 tw-flex md:tw-flex-shrink-0 md:tw-items-center">
                        <ShowOrHideByLastClick
                          timestamp={lastChangeDate}
                          component={WhatsNewButton}
                          variableName={"lastChangelogClick"}
                        />
                        {/* dropdown */}
                        <Menu as="div" className="tw-relative md:tw-block tw-hidden tw-ml-3">
                          <div>
                            <Menu.Button className="tw-p-0 tw-relative tw-flex tw-rounded-full tw-bg-white tw-text-sm hover:tw-brightness-90">
                              <span className="tw-absolute tw--inset-1.5" />
                              <span className="tw-sr-only">Open user menu</span>
                              {getAvatarImage(session.user, 34)}
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="tw-transition tw-ease-out tw-duration-200"
                            enterFrom="tw-transform tw-opacity-0 tw-scale-95"
                            enterTo="tw-transform tw-opacity-100 tw-scale-100"
                            leave="tw-transition tw-ease-in tw-duration-75"
                            leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
                            leaveTo="tw-transform tw-opacity-0 tw-scale-95"
                          >
                            <Menu.Items
                              className="tw-shadow-2xl tw-w-auto tw-absolute tw-right-0 tw-z-10 tw-mt-2 tw-origin-top-right tw-rounded-xl tw-bg-white tw-py-1 focus:tw-outline-none"
                              style={{ border: "1px solid whitesmoke " }}
                            >
                              <Menu.Item>
                                <div
                                  className="tw-p-4 tw-border-opacity-100 tw-flex"
                                  style={{
                                    borderBottom: "2px solid rgb(248, 249, 250)",
                                  }}
                                >
                                  {" "}
                                  {getAvatarImage(session.user, 40)}
                                  <div className="tw-mx-4">
                                    <h5 className="tw-m-0 tw-capitalize tw-text-[1rem] ">
                                      {extractName(session.user)}
                                    </h5>
                                    <h6 className="tw-m-0 tw-text-gray-400">{session.user.email}</h6>
                                  </div>
                                </div>
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href={`/settings?selectedTab=${SETTINGS_TAB.INTEGRATIONS}`}
                                    className={classNames(
                                      active ? "tw-bg-gray-100" : "",
                                      "tw-flex tw-p-4 tw-text-base tw-text-gray-600 hover:tw-text-gray-600 tw-no-underline"
                                    )}
                                  >
                                    <Settings className="tw-my-auto tw-mx-0 tw-h-5 tw-w-5" />
                                    <div className="tw-mx-4">Settings</div>
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    data-featurebase-link
                                    href="https://feedback.notion-widgets.com"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className={classNames(
                                      active ? "tw-bg-gray-100" : "",
                                      "tw-flex tw-p-4 tw-text-base tw-text-gray-600 hover:tw-text-gray-600 tw-no-underline"
                                    )}
                                  >
                                    <Star className="tw-my-auto tw-mx-0 tw-h-5 tw-w-5" />
                                    <div className="tw-mx-4">Feedback Board</div>
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href="#"
                                    onClick={handleOpenHelp}
                                    className={classNames(
                                      active ? "tw-bg-gray-100" : "",
                                      "tw-flex tw-p-4 tw-text-base tw-text-gray-600 hover:tw-text-gray-600 tw-no-underline"
                                    )}
                                  >
                                    <HelpCircle className="tw-my-auto tw-mx-0 tw-h-5 tw-w-5" />
                                    <div className="tw-mx-4">Support</div>
                                  </Link>
                                )}
                              </Menu.Item>
                              <Menu.Item>
                                {({ active }) => (
                                  <Link
                                    href="#"
                                    className={classNames(
                                      active ? "tw-bg-gray-100" : "",
                                      "tw-flex tw-p-4 tw-text-base tw-text-gray-600 hover:tw-text-gray-600 tw-no-underline"
                                    )}
                                    onClick={() => signOut({ callbackUrl: "/" })}
                                    style={{
                                      borderTop: "2px solid rgb(248, 249, 250)",
                                    }}
                                  >
                                    <LogOut className="tw-my-auto tw-mx-0 tw-h-5 tw-w-5" />
                                    <div className="tw-mx-4">Log out</div>
                                  </Link>
                                )}
                              </Menu.Item>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                        <Disclosure.Button className="md:tw-hidden tw-contents">
                          <div className="tw-p-0 tw-relative tw-flex tw-rounded-full tw-bg-white tw-text-sm hover:tw-brightness-90">
                            <span className="tw-absolute tw--inset-1.5" />
                            <span className="tw-sr-only">Open user menu</span>
                            {getAvatarImage(session.user, 34)}
                          </div>
                        </Disclosure.Button>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="tw--ml-2 tw-mr-2 tw-flex tw-items-center md:tw-hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button
                      className="tw-relative tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-p-2 tw-text-black hover:tw-bg-gray-100 hover:tw-text-gray-500"
                      style={{ border: "none" }}
                    >
                      <span className="tw-absolute tw--inset-0.5" />
                      <span className="tw-sr-only">Open main menu</span>
                      {open ? (
                        <X className="tw-block tw-h-6 tw-w-6" aria-hidden="true" />
                      ) : (
                        <Bar className="tw-block tw-h-6 tw-w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                )}
              </div>
            </div>
            {/* mobile view nav */}
            <Disclosure.Panel className="md:tw-hidden" style={{ borderTop: "1px solid white" }}>
              <div className="tw-space-y-1 tw-pb-3 tw-pt-2">
                {(session ? loginHeader : loggedOutHeader).map((heading) => {
                  const { label, href, index } = heading;
                  return (
                    <Disclosure.Button
                      key={index}
                      as="a"
                      href={href}
                      className={`tw-flex tw-py-2 tw-pl-3 tw-pr-4 tw-text-base tw-font-medium tw-no-underline tw-text-white sm:tw-pl-5 sm:tw-pr-6 hover:tw-bg-orange-50 hover:tw-text-[#ff4a00]`}
                      style={
                        index === 3 || index === 5
                          ? {
                              borderBottom: "1px solid lightgrey",
                            }
                          : { borderBottom: "none" }
                      }
                    >
                      {heading.svg && (
                        <img
                          className="tw-mr-2 tw-mb-[4px]"
                          src={"/crown 1.svg"}
                          alt="plan icon"
                          width={19}
                          height={19}
                        />
                      )}
                      {label}
                      {heading?.count && (
                        <div className="tw-ml-2 tw-my-auto tw-text-xs tw-bg-white tw-text-[#ff4a00] tw-rounded-lg tw-px-[6px] tw-py-[2px] ">
                          {index === 1 ? widgetsCount : appsCount}
                        </div>
                      )}
                    </Disclosure.Button>
                  );
                })}
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
}
