import { FREE_PLAN_VIEWS_PER_MONTH, FREE_PLAN_WIDGETS } from "./constants";

const freePlan = {
  type: "free",
  name: "Free",
  maxWidgets: FREE_PLAN_WIDGETS,
  maxApps: 0,
  maxViews: FREE_PLAN_VIEWS_PER_MONTH,
};
const hobbyPlan = {
  type: "hobby",
  name: "Hobby",
  maxWidgets: 5,
  maxApps: 0,
  maxViews: FREE_PLAN_VIEWS_PER_MONTH,
};
const basicPlan = {
  type: "basic",
  name: "Basic",
  maxWidgets: 20,
  maxApps: 10,
  maxViews: 5000,
};
const proPlan = {
  type: "pro",
  name: "Pro",
  maxWidgets: 50,
  maxApps: 20,
  maxViews: 50000,
};
const ultimatePlan = {
  type: "ultimate",
  name: "Ultimate",
  maxWidgets: 200,
  maxApps: 100,
  maxViews: 250000,
};

const planTypes = [freePlan, hobbyPlan, basicPlan, proPlan, ultimatePlan];

const Pricing = [
  {
    id: "monthly_hobby",
    name: hobbyPlan.name,
    stripePlanId: "",
    planId: process.env.NEXT_PUBLIC_PADDLE_MONTHLY_HOBBY,
    type: "monthly",
    price: "2",
    anchorPrice: "2",
    priceDetails: "",
    priceDetailsBlackFriday: "",
    currency: "$",
    features: [`${hobbyPlan.maxWidgets} widgets`, "Removed Branding"],
  },
  {
    id: "monthly_basic",
    name: basicPlan.name,
    planId: process.env.NEXT_PUBLIC_PADDLE_MONTHLY_BASIC,
    stripePlanId: process.env.NEXT_PUBLIC_STRIPE_BASIC_MONTHLY_PLAN_ID,
    type: "monthly",
    price: "5",
    anchorPrice: "",
    priceDetails: "",
    priceDetailsBlackFriday: "",
    currency: "$",
    features: [
      `${basicPlan.maxWidgets} widgets`,
      `${basicPlan.maxApps} apps`,
      "5,000 views per month",
      "Removed Branding",
      "16 widget types",
      "Weather widgets",
      "Pomodoro & Recurring Tasks",
    ],
    mostPopular: false,
  },
  {
    id: "lifetime_basic",
    name: basicPlan.name,
    stripePlanId: process.env.NEXT_PUBLIC_STRIPE_BASIC_LIFETIME_PRICE,
    type: "lifetime",
    price: "179",
    anchorPrice: "39",
    priceDetails: "Pay once, use forever",
    priceDetailsBlackFriday: "",
    currency: "$",
    features: [
      `${basicPlan.maxWidgets} widgets`,
      `${basicPlan.maxApps} apps`,
      "Removed Branding",
      "5,000 views per month",
      "16 widget types",
      "Weather widgets",
      "Pomodoro & Recurring Tasks",
    ],
    mostPopular: false,
  },
  {
    id: "monthly_pro",
    name: proPlan.name,
    planId: process.env.NEXT_PUBLIC_PADDLE_MONTHLY_PRO,
    stripePlanId: process.env.NEXT_PUBLIC_STRIPE_PRO_MONTHLY_PLAN_ID,
    type: "monthly",
    price: "9",
    anchorPrice: "",
    priceDetails: "",
    priceDetailsBlackFriday: "",
    currency: "$",
    features: [
      `${proPlan.maxWidgets} widgets`,
      `${proPlan.maxApps} apps`,
      "50,000 views per month",
      "Removed Branding",
      "20 widget types",
      "Paypal Button, Weather Forecast, KPI",
      "Embed widgets on website",
    ],
    mostPopular: true,
  },
  {
    id: "lifetime_pro",
    name: proPlan.name,
    stripePlanId: process.env.NEXT_PUBLIC_STRIPE_PRO_LIFETIME_PRICE,
    type: "lifetime",
    price: "299",
    anchorPrice: "59",
    priceDetails: "Pay once, use forever",
    priceDetailsBlackFriday: "",
    currency: "$",
    features: [
      `${proPlan.maxWidgets} widgets`,
      `${proPlan.maxApps} apps`,
      "50,000 views per month",
      "Removed Branding",
      "20 widget types",
      "Paypal Button, Weather Forecast, KPI",
      "Embed widgets on website",
    ],
    mostPopular: true,
  },
  {
    id: "monthly_ultimate",
    name: ultimatePlan.name,
    planId: process.env.NEXT_PUBLIC_PADDLE_MONTHLY_ULTIMATE,
    stripePlanId: process.env.NEXT_PUBLIC_STRIPE_ULTIMATE_MONTHLY_PLAN_ID,
    type: "monthly",
    price: "18",
    anchorPrice: "",
    currency: "$",
    features: [
      `${ultimatePlan.maxWidgets} widgets`,
      `${ultimatePlan.maxApps} apps`,
      "250,000 views per month",
      "Removed Branding",
      "+20 widget types",
      "Beta Features",
      "Embed widgets on website",
    ],
    mostPopular: false,
  },
  {
    id: "lifetime_ultimate",
    name: ultimatePlan.name,
    stripePlanId: process.env.NEXT_PUBLIC_STRIPE_ULTIMATE_LIFETIME_PRICE,
    type: "lifetime",
    price: "699",
    anchorPrice: "149",
    priceDetails: "Pay once, use forever",
    priceDetailsBlackFriday: "",
    currency: "$",
    features: [
      `${ultimatePlan.maxWidgets} widgets`,
      `${ultimatePlan.maxApps} apps`,
      "250,000 views per month",
      "Removed Branding",
      "+20 widget types",
      "Beta Features",
      "Embed widgets on website",
    ],
    mostPopular: false,
  },
  {
    id: "yearly_hobby",
    name: hobbyPlan.name,
    planId: process.env.NEXT_PUBLIC_PADDLE_YEARLY_HOBBY,
    stripePlanId: "",
    type: "yearly",
    price: "20",
    anchorPrice: "2",
    priceDetails: "Billed $24 annually",
    priceDetailsBlackFriday: "Billed $24 annually",
    currency: "$",
    features: [`${hobbyPlan.maxWidgets} widgets`, "Removed Branding"],
  },
  {
    id: "yearly_basic",
    name: basicPlan.name,
    planId: process.env.NEXT_PUBLIC_PADDLE_YEARLY_BASIC,
    stripePlanId: process.env.NEXT_PUBLIC_STRIPE_BASIC_YEARLY_PLAN_ID,
    type: "yearly",
    price: "4",
    anchorPrice: "",
    priceDetails: "Billed $48 annually",
    priceDetailsBlackFriday: "Billed $48 annually",
    currency: "$",
    features: [
      `${basicPlan.maxWidgets} widgets`,
      `${basicPlan.maxApps} apps`,
      "Removed Branding",
      "5,000 views per widget",
      "16 widget types",
      "Weather widgets",
      "Pomodoro & Recurring Tasks",
    ],
    mostPopular: false,
  },
  {
    id: "yearly_pro",
    name: proPlan.name,
    planId: process.env.NEXT_PUBLIC_PADDLE_YEARLY_PRO,
    stripePlanId: process.env.NEXT_PUBLIC_STRIPE_PRO_YEARLY_PLAN_ID,
    type: "yearly",
    price: "7",
    anchorPrice: "",
    priceDetails: "Billed $84 annually",
    priceDetailsBlackFriday: "Billed $67.20 1st yr, $84/yr after",
    currency: "$",
    features: [
      `${proPlan.maxWidgets} widgets`,
      `${proPlan.maxApps} apps`,
      "50,000 views per widget",
      "Removed Branding",
      "20 widget types",
      "Paypal Button, Weather Forecast, KPI",
      "Embed widgets on website",
    ],
    mostPopular: true,
  },
  {
    id: "yearly_ultimate",
    name: ultimatePlan.name,
    planId: process.env.NEXT_PUBLIC_PADDLE_YEARLY_ULTIMATE,
    stripePlanId: process.env.NEXT_PUBLIC_STRIPE_ULTIMATE_YEARLY_PLAN_ID,
    type: "yearly",
    price: "14",
    anchorPrice: "",
    priceDetails: "Billed $168 annually",
    priceDetailsBlackFriday: "Billed $134.40 1st yr, $168/yr after",
    currency: "$",
    features: [
      `${ultimatePlan.maxWidgets} widgets`,
      `${ultimatePlan.maxApps} apps`,
      "250,000 views per month",
      "Removed Branding",
      "+20 widget types",
      "Beta Features",
      "Embed widgets on website",
    ],
    mostPopular: false,
  },
];

export const getPlanNameFromPlanId = (planId) => {
  let plan = Pricing.find((o) => o.planId === planId);
  return plan ? plan.name : checkOlderPlans(planId);
};

export const getWidgetsLimit = (planName) => {
  const plan = planTypes.find((o) => o.name === planName);
  return plan ? plan.maxWidgets : FREE_PLAN_WIDGETS;
};

export const getAppsLimit = (planName) => {
  const plan = planTypes.find((o) => o.name === planName);
  return plan ? plan.maxApps : 0;
};

export const getViewsLimit = (planName) => {
  const plan = planTypes.find((o) => o.name === planName);
  return plan ? plan.maxViews : FREE_PLAN_VIEWS_PER_MONTH;
};

export const checkOlderPlans = (planId) => {
  console.log(">>> Plan not found, checking older plans");

  let result = "no-plan";
  if (planId === "819131" || planId === "839649" || planId === "819130") {
    result = "Hobby";
  } else if (planId === "649939" || planId === "649905") {
    result = "Basic";
  } else if (planId === "649906" || planId === "649940") {
    result = "Pro";
  } else if (planId === "649908" || planId === "649941") {
    result = "Ultimate";
  }
  console.log(">>> Old Plan found", result);

  return result;
};

export default Pricing;
134.4;
