import useSWR from "swr";

const fetcher = async (url) => {
  const response = await fetch(url);

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }

  return response.json();
};

function useViewsCount(email) {
  const { data, error, isLoading } = useSWR(email ? `/api/views` : null, fetcher, {
    revalidateOnFocus: true,
    revalidateOnMount: true,
  });

  return {
    views: data?.views || 0,
    isLoading,
    isError: error,
  };
}

export default useViewsCount;
