import { NO_FILTER_OPTION } from "../components/common/notion/helpers/constants";

export const getTitle = (type, rec) => {
  if (type === "database") {
    if (rec?.title[0]?.plain_text) {
      return rec.title[0].plain_text;
    }
  }
  if (type === "page") {
    const titleProp = getTitleRecordFromProperties(rec.properties);
    return rec.properties[titleProp].title[0]?.text.content || rec.url;
  }
};

// https://developers.notion.com/reference/property-object#database-properties
// https://developers.notion.com/reference/post-database-query-filter#text-filter-condition
const supportedPropTypes = [
  "title",
  "rich_text",
  "number",
  "select",
  "multi_select",
  "date",
  "checkbox",
  "files",
  "url",
  "email",
  "phone_number",
  "created_time",
  "last_edited_time",
  // "people"
  // "status",
];

export const getPropertiesOptions = (props, noFilter) => {
  const out = [];
  if (noFilter) {
    out.push(NO_FILTER_OPTION);
  }
  for (let key in props) {
    const { id, name, type } = props[key];
    if (supportedPropTypes.includes(type)) {
      out.push({
        value: id,
        label: name,
      });
    }
  }
  return out;
};

const EQUALS = {
  value: "equals",
  label: "Equals to",
};
const NOT_EQUALS = {
  value: "does_not_equal",
  label: "Not equals to",
};
const CONTAINS = {
  value: "contains",
  label: "Contains",
};
const DOES_NOT_CONTAINS = {
  value: "does_not_contain",
  label: "Does not contain",
};

const STARTS_WITH = {
  value: "starts_with",
  label: "Starts with",
};

const ENDS_WITH = {
  value: "ends_with",
  label: "Ends with",
};

const GREATER_THAN = {
  value: "greater_than",
  label: "Greater than",
};
const LESS_THAN = {
  value: "less_than",
  label: "Less than",
};

const IS = {
  value: "is",
  label: "is",
};

const IS_EMPTY = {
  value: "is_empty",
  label: "Is empty",
};

const IS_NOT_EMPTY = {
  value: "is_not_empty",
  label: "Is not empty",
};

const TRUE = {
  value: "true",
  label: "True",
};

const FALSE = {
  value: "false",
  label: "False",
};

const IS_BEFORE = {
  value: "before",
  label: "Before",
};

const IS_TODAY = {
  value: "is_today",
  label: "Is today",
};

const IS_NEXT_WEEK = {
  value: "next_week",
  label: "Is within next week",
};

const IS_PAST_WEEK = {
  value: "past_week",
  label: "Is within past week",
};

const IS_NEXT_MONTH = {
  value: "next_month",
  label: "Is within next month",
};

const IS_PAST_MONTH = {
  value: "past_month",
  label: "Is within past month",
};

const IS_NEXT_YEAR = {
  value: "next_year",
  label: "Is within next year",
};

const IS_PAST_YEAR = {
  value: "past_year",
  label: "Is within past year",
};

const IS_AFTER = {
  value: "after",
  label: "After",
};

export const getTypeOptions = (type) => {
  if (type === "number") {
    return [EQUALS, NOT_EQUALS, GREATER_THAN, LESS_THAN, IS_EMPTY, IS_NOT_EMPTY];
  }
  if (type === "select" || type === "multi_select") {
    return [EQUALS, NOT_EQUALS, IS_EMPTY, IS_NOT_EMPTY];
  }
  if (type === "people") {
    return [CONTAINS, DOES_NOT_CONTAINS, IS_EMPTY, IS_NOT_EMPTY];
  }
  if (type === "status") {
    return [EQUALS, NOT_EQUALS, IS_EMPTY, IS_NOT_EMPTY];
  }
  if (type === "date" || type === "created_time" || type === "last_edited_time") {
    // https://developers.notion.com/reference/post-database-query-filter#date-filter-condition
    return [EQUALS, IS_BEFORE, IS_AFTER, IS_EMPTY, IS_NOT_EMPTY, IS_PAST_WEEK, IS_PAST_MONTH, IS_PAST_YEAR, IS_NEXT_WEEK, IS_NEXT_MONTH, IS_NEXT_YEAR];
  }

  if (type === "title" || type === "rich_text" || type === "url" || type === "email" || type === "phone_number") {
    return [EQUALS, NOT_EQUALS, CONTAINS, DOES_NOT_CONTAINS, STARTS_WITH, ENDS_WITH, IS_EMPTY, IS_NOT_EMPTY];
  }

  if (type === "checkbox") {
    return [TRUE, FALSE];
  }

  if (type === "files") {
    return [IS_EMPTY, IS_NOT_EMPTY];
  }

  // if (type === "formula") {
  //   return [EQUALS];
  // }
};

export const needValueForOperator = (field) => {
  const hiddenFieldOperators = [IS_TODAY, IS_NEXT_WEEK, IS_NEXT_MONTH, IS_NEXT_YEAR, IS_PAST_WEEK, IS_PAST_MONTH, IS_PAST_YEAR, IS_EMPTY, IS_NOT_EMPTY, TRUE, FALSE];
  return !hiddenFieldOperators.find((rec) => rec.value === field);
};

export const getTitleRecordFromProperties = (properties) => {
  for (let key in properties) {
    const data = properties[key];
    if (data.type === "title") {
      return key;
    }
  }
  return false;
};

export const getPageTitles = (pages = []) => {
  const out = [];
  if (pages && pages.length > 0) {
    const titleKey = String(getTitleRecordFromProperties(pages[0].properties));
    for (let page of pages) {
      out.push({
        label: page.properties[titleKey].title[0]?.text.content || page.url,
        value: page.id,
        page: page,
      });
    }
    return out;
  }
  return [];
};

export const getNotionValue = (value) => {
  var notionValue;
  function isDirect() {
    if (value[value.type] === null) return null;
    return (notionValue = value[value.type]);
  }
  function isSelect() {
    if (!value.select) return null;
    return (notionValue = value.select.name);
  }
  function isMultiSelect() {
    return (notionValue = value[value.type].map((v) => v.name));
  }
  function isRichText() {
    if (value.rich_text.length === 0) return null;
    return (notionValue = value.rich_text[0].plain_text.slice(0, 60));
  }
  function isFormula() {
    return (notionValue = value.formula[value.formula.type]);
  }
  function isTitle() {
    if (value.title.length === 0) return null;
    return (notionValue = value.title[0].plain_text);
  }
  function isPerson() {
    if (Array.isArray(value[value.type])) {
      return (notionValue = value[value.type].map((v) => v.name));
    }
    if (!value[value.type]) return null;
    return (notionValue = value[value.type].name);
  }
  function isRelation() {
    return (notionValue = value[value.type].map((v) => v.id));
  }
  function isFile() {
    return (notionValue = value.files.length);
  }
  function isStatus() {
    if (!value.status) return null;
    return (notionValue = value.status.name);
  }
  function isUniqueId() {
    if (!value.unique_id) return null;
    return (notionValue = !value.unique_id.prefix ? value.unique_id.number : value.unique_id.prefix + "-" + value.unique_id.number);
  }

  var fs = {
    rich_text: isRichText,
    select: isSelect,
    number: isDirect,
    url: isDirect,
    checkbox: isDirect,
    formula: isFormula,
    multi_select: isMultiSelect,
    date: isDirect,
    title: isTitle,
    email: isDirect,
    created_time: isDirect,
    last_edited_time: isDirect,
    phone_number: isDirect,
    last_edited_by: isPerson,
    created_by: isPerson,
    people: isPerson,
    relation: isRelation,
    files: isFile,
    status: isStatus,
    unique_id: isUniqueId,
    button: isDirect,
  };

  if (!value || !value.type) {
    return [];
  }

  if (value.type === "rollup") {
    value = value.rollup;
  }
  let type = value.type;
  if (type === "array") {
    if (value.array.length === 0) return [];
    value = value.array
      .map((rollup) => {
        try {
          return getNotionValue(rollup);
        } catch (e) {
          console.error(e, rollup);
          return "";
        }
      })
      .flat();
    return value;
  }

  return fs[value.type]();
};
