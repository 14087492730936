import dayjs from "dayjs";
import moment from "moment";
import { getTitle } from "./notion-helpers";

export const formatDateUI = (date) => {
  const d = new Date(date);
  return moment(d).format("YYYY/MM/DD hh:mma");
};

export const formatDateDB = (date) => {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);
  return d.toISOString();
};

export const dateFormat = (date, format = "YYYY-MM-DD HH:mmZ") => {
  return dayjs(date).format(format);
};

export const registerHelpers = (handle) => {
  handle.registerHelper("ifEquals", function (arg1, arg2, options) {
    return arg1 == arg2 ? options.fn(this) : options.inverse(this);
  });
  handle.registerHelper("safeVal", function (value, safeValue) {
    var out = value || safeValue;
    return new handle.SafeString(out);
  });
  return handle;
};

export const sleepForMS = (ms) => new Promise((r) => setTimeout(r, ms));

export const arrayRange = (start, stop, step) =>
  Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step);

export const getOptions = (type, recs) => {
  let out = [];
  recs.forEach((rec) => {
    const title = getTitle(type, rec);
    out.push({ value: rec.id, label: title });
  });
  out = out.sort((a, b) => {
    return a?.label?.localeCompare(b?.label);
  });
  return out;
};

export async function getNotionDatabases() {
  const response = await fetch(`/api/notion/database`);
  const data = await response.json();
  return data.data;
}
